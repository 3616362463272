import $ from "jquery";
import { observer } from "mobx-react";
import React, { ChangeEvent, Component } from "react";
import { FocusTrap } from "@mantine/core";
import styled from "styled-components";

import { gQ } from "../AppContext";

const StyledPlayerInput = styled.textarea`
  border: none;
  outline: none;
  box-shadow: none !important;
  font-size: 18px;
  line-height: 24px;
  padding: 15px;
  width: 60%;
  float: right;
  height: 17.5vh;
  background: black;
  color: #54fe54;
  border-radius: 10px;
`;

const PlayerInput = observer(
  class PlayerInput extends Component<{}, {}> {
    private handleChange(e: ChangeEvent<HTMLTextAreaElement>) {
      const playerInput = $(e.target).val();
      gQ.tryPlayerInput(String(playerInput));
    }
    public render() {
      const active = true;
      return (
        <FocusTrap active={active}>
          <StyledPlayerInput
            className="playerInput"
            placeholder={gQ.currentPromptOrQuestion}
            onChange={this.handleChange}
          />
        </FocusTrap>
      );
    }
  }
);

export default PlayerInput;
