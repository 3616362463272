import Routes from './Routes';
import Questa from './QUESTA/Questa';

class RootStore {
    gQ: Questa;
    routes: Routes;
    constructor() {
        this.gQ = new Questa(this);
        this.routes = new Routes(this);
    }
}

export default RootStore;
