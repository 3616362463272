import { observer } from "mobx-react";
import React, { Component, forwardRef } from "react";
import styled from "styled-components";

import { Tooltip } from "@mantine/core";

import { gQ } from "../AppContext";

const ForwardedStyledBall = forwardRef<HTMLDivElement>((props, ref) => (
  <div ref={ref} {...props} />
));

const StyledBall = styled(ForwardedStyledBall)`
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 3px rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  margin: 10px;
  float: left;
  cursor: pointer;
`;

const StyledCommandKeeper = styled.div`
  width: 40%;
  height: 23vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: space-around;
  padding: 40px;
  border-radius: 10px;
`;

const PossibleActions = observer(
  class PossibleActions extends Component<{}, {}> {
    render() {
      const { gameActions, latestGameActions } = gQ;
      if (!gameActions) return false;
      const commandDivs = latestGameActions.map((instr) => {
        //these are hints that there are things you're still missing
        return (
          <Tooltip
            label={instr.matchString}
            color="green"
            withArrow
            key={"instr" + instr.matchString}
          >
            <StyledBall data-title={instr.matchString} />
          </Tooltip>
        );
      });
      return <StyledCommandKeeper>{commandDivs}</StyledCommandKeeper>;
    }
  }
);

export default PossibleActions;
