import $ from "jquery";
import { observer } from "mobx-react";
import React, { ChangeEvent, Component } from "react";
import styled from "styled-components";

import { gQ } from "../AppContext";

const StyledMakerInput = styled.textarea`
  border: none;
  outline: none;
  box-shadow: none !important;
  font-size: 16px;
  line-height: 22px;
  height: 67vh;
  width: 40%;
  float: left;
  padding: 30px;
  background: #f9f9f9;
  color: #444;
  border-radius: 10px;
`;

const MakerInstructions = observer(
  class MakerInstructions extends Component<{ instructions: string }, {}> {
    constructor(props: any) {
      super(props);
      const { instructions } = this.props;
      if (instructions) gQ.setGameText(instructions);
    }
    private handleMakerChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      const text = String($(e.target).val());
      gQ.setGameText(text);
    };
    public render() {
      return (
        <StyledMakerInput
          value={gQ.latestGameText}
          onChange={this.handleMakerChange}
          placeholder={"Type your game instructions here."}
        />
      );
    }
  }
);

export default MakerInstructions;
