import { observer } from "mobx-react";
import React, { Component } from "react";
import styled from "styled-components";

import GameScreen from "./GameScreen";
import Inventory from "./Inventory";
import MakerInstructions from "./MakerInstructions";
import PlayerInput from "./PlayerInput";
import PossibleActions from "./PossibleActions";

const StyledViewer = styled.div`
  padding: 0 !important;
  margin: 0;
  height: 100%;
  overflow: hidden;

  &:hover {
    outline: none !important;
  }

  * {
    box-sizing: border-box;
    font-family: monospace;
  }
`;

const QuestaPlayer = observer(
  class QuestaPlayer extends Component<{ game?: string }, {}> {
    render() {
      const { game } = this.props;
      console.log(game);
      return (
        <StyledViewer id="viewer">
          <MakerInstructions
            key="questaMakerInstructions"
            instructions={game}
          />
          <GameScreen key="questaGameScreen" />
          <PlayerInput key="questaPlayerInput" />
          <Inventory key="questaInventory" />
          <PossibleActions key="questaPossibleActions" />
        </StyledViewer>
      );
    }
  }
);

export default QuestaPlayer;
