import React from "react";
import { createRoot } from "react-dom/client";

import { MantineProvider } from "@mantine/core";

import { QuestaPlayer } from "./QUESTA";
import { QHeader } from "./QUESTA/Header";

//pressing up should populate a field with what your last command was?
const container = document.getElementById("app");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

const fallbackGame = `
Welcome to Q.U.E.S.T.A. Edit the text here, and the game in the right column will update.

Start with a "game setup " and an equals sign, then add commands. Commands are separated by ++. Below, example of a game setup using "set" and "print" commands.

game setup = set $bear Blue ++ set $doors 2 ++ print You wake up and see a stuffed carnival bear named $bear wedged between two doors. One of them could be your way out. ++ set $name ask What is your name?

player types my name = print $name ++ prompt Welcome to QUESTA, $name
 
player types hello = print Hi $name, it's nice to see you. ++ set $lastName ask Now, your last name, $name

player types now what = print Now, $name $lastName, we part ways.


A player will see this text when they enter the game. In fact, the text you see now in green was generated from this text. Try changing the $bear variable to "Blueberry"!

Next, enter a line to define the the setting:

game setting = You're in a room with $doors doors and a carnival bear named $bear.

Next, enter a line to connect what a player types to the setting we just defined:

player types (look) (around|room) = print $setting --always

Try typing "look room" in the right pane, then hit enter to see what happens!

"game setting" corresponds to a "setting" variable, which you can use to describe the setting.
the --always flag allows a player-typed action to happen more than once in the same way.

player types where am i = print $setting --always

player types blue = print $bear

player types look bear = print You look at the bear. One of its eyes has been chewed off. It's a sad state of affairs, looking at this bear.

player types (look for|find) eye = print You scour the floor of the room looking for the bear's eye. Ultimately you realize it doesn't matter. It's never mattered. You do happen to find a grubby looking note, however. ++ make 1 Grubby Note ++ get 1 Grubby Note

player types (read|look|inspect) (note|grubby note) = print The note is scrawled in some shade of dark blue shimmering eyeliner. You can't really read it but from what you can make out, there's a phone number. Looks like it says 851-smudge-3012

player types (google|yahoo|bing) (851-smudge-3012|number) = print Are you really trying to google that smudgy eyeliner phone number? You pull out your phone and start googling but the internet doesn't work for some reason. (Did you expect it to work?)

player types (get|grab|take|hold|pick up) bear = print You can't keep your grubby mitts off this bear for some reason. ++ make 1 Stuffed Bear ++ get 1 Stuffed Bear

player types use bear = print You gnaw and tear at the teddy bear for several hours until you have several fine, long rope-like strands. You tie them together. ++ drop 1 Stuffed Bear ++ make 1 Stuffed Bear Rope ++ get 1 Stuffed Bear Rope

player types look doors = print You look at the doors. They're amazing. If you want to get out of this room, you'll have to do more than look at these damned amazing doors. The door to the left is green and the door to the right is blue.

player types (move|push|pull|kick) (bear|stuffed bear|carnival bear) = print You move the bear out of the way. The door to the left is green and the door to the right is blue.

player types (open|choose|go) (green door|left door) = print You choose the left door. You open it and find a pile of strange sandpapery boxes.

player types (open|choose|go) (blue door|right door) = print You choose the right door. You open it and find it leads to a small courtyard. There is a well in the middle. On the other side of the well are a red door, a yellow door, and a purple door. ++ setting You're in a small courtyard with a well in the middle. It's cold outside. You aren't adequately dressed. Behind you is a blue door. Ahead of you are a red door, a yellow door, and a purple door.

player types (explore|investigate|look) (well) = print You go toward the well. It's dark, it's scary. It's old and it doesn't look like it's been in use in the last fifty years.

player types climb down = print You try to climb down the well but it's too slippery. You accidentally lose your footing and slip. You fall to the bottom of the well. ++ setting Looks like you're at the bottom of a well. It's dark except for a pin of light above you.

player types (look|go|inspect|check out|visit) (well) = print You go to the well and look down. You can't see very far, and it's dark. But you swear there's something shiny at the bottom of the well.
`;

const mainLinks = [
  //  { link: "https://github.com/AdamHolwerda/QUESTA/wiki", label: "Learn" },
  //  { link: "/games", label: "Games" },
  // { link: "/forums", label: "Forums" },
];

const userLinks = [
  { link: "/docs", label: "Docs" },
  // { link: "/login", label: "Login" },
  // { link: "/signup", label: "Sign Up" },
];

root.render(
  <MantineProvider theme={{ colorScheme: "light" }}>
    <QHeader mainLinks={mainLinks} userLinks={userLinks} />
    <QuestaPlayer game={fallbackGame} />
  </MantineProvider>
);
