import { observer } from "mobx-react";
import React, { Component } from "react";
import styled from "styled-components";

import { gQ } from "../AppContext";

const StyledGameScreen = styled.div`
  height: 55vh;
  width: 60%;
  float: right;
  padding: 20px;
  font-size: 18px;
  line-height: 24px;
  color: #54fe54;
  background: black;
  overflow-y: auto;
  border-bottom: 3px solid white;
  border-radius: 10px;
  img {
    width: 100%;
  }
  > div {
    padding-bottom: 110px;
  }
`;

const GameScreen = observer(
  class GameScreen extends Component<{}, {}> {
    public render() {
      if (!gQ) return false;
      const gameLines = gQ.latestGameLines;
      const theLines = gameLines.map((line, i) => {
        return <div key={"gameLine" + i}>{line}</div>;
      });
      return (
        <StyledGameScreen>
          <div>{theLines}</div>
        </StyledGameScreen>
      );
    }
  }
);

export default GameScreen;
