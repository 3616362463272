import {
  action,
  autorun,
  computed,
  configure,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from "mobx";
import React from "react";

import RootStore from "./RootStore";

configure({ enforceActions: "observed" }); // strict mode

class Routes {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeObservable(this, {
     
    });

    this.rootStore = rootStore;
  }
}

export default Routes;