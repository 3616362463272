import { observer } from 'mobx-react';
import React, { Component } from 'react';
import styled from 'styled-components';

import { gQ } from '../AppContext';

const StyledInventory = styled.div`
    height: 17.5vh;
    padding: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    position: relative;
    color: #54FE54;
    background: #222;
    width: 60%;
    float:right;
    border-radius: 10px;
    &:before {
      content: 'Inventory';
      position: absolute;
      left: 5px;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 1px;
      color: #aaa;
      top: 5px;
    }
`
const Inventory = observer(
    class Inventory extends Component<{}, {}> {
        public render() {
            if (!gQ.playerInventory) return false;
            const theInventory = gQ.latestPlayerInventory;
            let itemNameCounter = 0;
            let currentItemName = '';
            const theItems = theInventory.map((item, i) => {
                if (item === currentItemName) {
                    itemNameCounter++;
                }
                currentItemName = item; //set current item to this
                return <div key={`inventory_${item}_${i}`}>{item}</div>;
            });
            
            return (
                <StyledInventory>
                    {theItems}
                </StyledInventory>
            );
        }
    }
);

export default Inventory;
