import $ from "jquery";
import {
  action,
  autorun,
  computed,
  configure,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from "mobx";
import React from "react";

import RootStore from "../RootStore";

declare global {
  interface Window {
    $: JQuery;
    jQuery: JQuery;
  }

  interface Navigator {
    msSaveBlob: any;
  }
}

configure({ enforceActions: "observed" }); // strict mode

interface GameAction {
  flags: { [key: string]: string };
  matchString: string;
  then: string;
  playerLvl: number;
  accessed: boolean;
  accessedTimes: number;
  immortal: boolean;
}
// internal functions starting with _ are considered "verbs"
// and should only be used as such
// as the output of different verbs from the _do method.
class Questa {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeObservable(this, {
      options: observable,
      makerInstructions: observable,
      contextualGame: observable, // url of loom this game is part of
      gameText: observable,
      gameLines: observable,
      gameActions: observable,
      variables: observable,
      playerActions: observable,
      playerInventory: observable,
      settingInventory: observable,
      questionVariable: observable,
      gameStartText: observable,
      input: observable,
      question: observable,
      gameInventory: observable,
      playerLevel: observable,
      playerSetting: observable,
      playerAnswers: observable,
      gamePrompt: observable,
      choiceContext: observable,
      activeChoice: observable,
      askingQuestion: observable,
      latestGameText: computed,
      gameStartedText: computed,
      latestInput: computed,
      latestPlayerInventory: computed,
      latestSettingInventory: computed,
      latestGameLines: computed,
      playerChoosing: computed,
      latestVariables: computed,
      currentChoosing: computed,
      currentSetting: computed,
      setupPlayerAction: action,
      contextualPlayerInfo: observable, //we store {as25fa: {playerLevel: 3, playerInventory: []}}
      latestContextualPlayerInfo: computed,
      updateContextualPlayerInfo: action,
      latestContextualGame: computed,
      contextualGameInfo: computed,
      latestPlayerSetting: computed,
      latestPlayerActions: computed,
      _prompt: action,
      _ask: action,
      _answer: action,
      _do: action,
      _make: action,
      _unmake: action,
      _drop: action,
      _set: action,
      _unset: action,
      spliceFromGameActions: action,
      spliceFromInventory: action,
      _get: action,
      _setting: action,
      _use: action,
      printGameLine: action,
      _print: action,
      _act: action,
      _choose: action,
      _attach: action,
      _navigate: action,
      createGameActions: action,
      collectMakerInstructions: action,
      startedGameWith: action,
      resetPlayer: action,
      clearPlayerInput: action,
      latestGameActions: computed,
      latestMakerInstructions: computed,
      latestGamePrompt: computed,
      currentPromptOrQuestion: computed,
      currentPlayerLevel: computed,
      currentQuestionVariable: computed,
      currentQuestion: computed,
      currentPlayerAnswers: computed,
      currentAskingQuestion: computed,
      tryPlayerInput: action,
      setGameText: action,
      start: action,
    });

    this.rootStore = rootStore;
  }
  options = {
    showMakerInstructions: true,
    speakScreens: false,
  };
  askingQuestion = false;
  makerInstructions = [];
  gameText = "";
  gameLines: any[] = [];
  gameActions: GameAction[] = [];
  playerActions = [];
  playerInventory = [];
  question = "";
  questionAnswer = "";
  settingInventory = [];
  variables = {};
  gameStartText = "";
  input: string = "";
  gameInventory: string[] = [];
  questionVariable: string = "";
  playerLevel: number = 0;
  playerAnswers: [{ [key: string]: string }] = [{}];
  playerSetting: string = "";
  gamePrompt: string = "What do you do? (hit enter to submit)";
  choiceContext: boolean = false;
  activeChoice: object = {};
  contextualPlayerInfo: object = {};
  contextualGame: string = "";

  get latestContextualPlayerInfo() {
    return this.contextualPlayerInfo;
  }
  get latestContextualGame() {
    return this.contextualGame || "a";
  }
  get contextualGameInfo() {
    return this.latestContextualPlayerInfo[this.latestContextualGame];
  }
  get latestGameText() {
    return this.gameText;
  }
  get gameStartedText() {
    return this.gameStartText || "";
  }
  get latestVariables() {
    return this.variables;
  }
  get latestInput() {
    return this.input;
  }
  get latestPlayerInventory() {
    return this.playerInventory;
  }
  get latestPlayerSetting() {
    return this.playerSetting;
  }
  get latestPlayerActions() {
    return this.playerActions;
  }
  get latestSettingInventory() {
    return this.settingInventory;
  }
  get latestGameLines() {
    return this.gameLines;
  }
  get playerChoosing() {
    return this.choiceContext;
  }
  get currentChoosing() {
    return this.playerChoosing ? this.activeChoice : {};
  }
  get currentAskingQuestion() {
    return this.askingQuestion;
  }
  get currentQuestionVariable() {
    return this.questionVariable;
  }
  get currentQuestion() {
    return this.question;
  }
  get currentQuestionAnswer() {
    return this.questionAnswer;
  }
  get currentPromptOrQuestion() {
    return this.currentAskingQuestion
      ? this.currentQuestion
      : this.latestGamePrompt;
  }
  get currentSetting() {
    let itemString = "";
    const settingInventory = this.latestSettingInventory;
    if (settingInventory?.length) {
      itemString = " There's also ";
      settingInventory.forEach((inventoryItem, i) => {
        let andString = " and ";
        // are there duplicates?
        const numOfItem = settingInventory.filter((item) => {
          return item === inventoryItem;
        }).length;
        // are we on the last item? put a period
        if (i === settingInventory.length - 1) andString = ".";
        const addNum = numOfItem > 1 ? ` (${numOfItem})` : ``;
        const item = " " + inventoryItem + addNum;
        const a = numOfItem > 1 ? " " : "a ";
        // have we already added the item and quantity? don't do it again
        if (itemString.includes(` ${inventoryItem} `)) return false;
        itemString += numOfItem + item + andString;
      });
    }
    //cleanup weird stuff
    if (itemString.endsWith(" and ")) {
      itemString = itemString.substring(0, itemString.length - 5);
      itemString += ".";
    }
    return this.playerSetting + itemString.replace(new RegExp("  ", "g"), " ");
  }
  get latestGameActions() {
    return this.gameActions;
  }
  get latestMakerInstructions() {
    return this.makerInstructions;
  }
  get latestGamePrompt() {
    return this.gamePrompt;
  }
  get currentPlayerLevel() {
    return this.playerLevel;
  }
  get currentPlayerAnswers() {
    return this.playerAnswers;
  }
  verbs = [
    "act", // has to be first, "act" adds a new action to the player actions pool
    "prompt", // "prompt"ing something changes the prompt
    "ask", //ask-ing allows you to grab a value from a user based on a question, and reuse it later
    "answer", //answer lets you respond to a question
    "print", // "say"ing something prints to the output
    "set", // "set" allows the game maker to make variables
    "unset", // "unset" removes variables
    "choose", // "choose"-ing an item prints two options to the screen, which the user can select or type
    "get", // "get"ing something adds stuff to inventory, and removes it from the setting
    "equip", // "equip"ing an item marks it as active in the player's inventory
    "unequip", //"unequip"ing an item marks it as inactive in the player's inventory
    "setting", //"setting" followed by a phrase changes a player's context / room
    "drop", //"drop"ing something removes stuff from the inventory
    "use", //"use"-ing an inventory item performs an action and removes the item from inventory
    "unmake", //has to be before make
    "make", //"make" puts things in the setting, which are visible as part of the $setting
    "navigate", //"navigate"-ing either sends a user to another loom or web address
    "attach", //"shows an image, video, or audio element in the output
  ];
  flags = [
    "always", //always is a flag that allows you to run an action even if the player has already done it
    "near", //near is a flag that can be used to make an action only available in certain contexts
    "has", // has is a flag that means "if the player has this item"
    "wearing", //wearing is a flag that means "if the player is wearing this item"
    "hasnt", // hasnt is a flag that means "if the player doesn't have this item"
    "when", // when is a flag that means "if the player has done this action"
    "if", // if is a flag that means "if this variable is set to this value"
  ];
  _prompt = (question: string) => {
    //the game maker has decided to change the question the player is asked.
    //the default question is "what do you do?"
    console.log(this.latestVariables);
    let pmpt: string = question ? question : "What do you do?";
    this.gamePrompt = this.replaceVariablesInText(pmpt);
  };
  _do = (phrase: string) => {
    const theNewPhrase = this.replaceVariablesInText(phrase);
    let exitLoop = false;
    if (theNewPhrase.indexOf(" ++ ") > -1) {
      //checking for multiple responses to a player input
      // such as exposition + item getting
      const thoseCommands = theNewPhrase.split(" ++ ");
      thoseCommands.forEach((command) => {
        this._do(command);
        exitLoop = true;
      });
      if (exitLoop) return false;
    }
    this.verbs.forEach((verb) => {
      // iterate through verbs,
      const theRest = theNewPhrase.replace(verb + " ", "");
      if (theNewPhrase.indexOf(verb) > -1) {
        // if the verb is there, do it
        this["_" + verb](theRest); // call function
        //after we've done something we should tell the user their input was received
        //but after a reasonable delay so they can see what they typed
        runInAction(() => {
          this.clearPlayerInput();
        });
        //we shouldn't continue to iterate through this phase
        return false;
      }
    });
  };
  _make = (item: string) => {
    //game maker is trying to "make 1 alarm clock";
    //maybe there's a central repository for devices that "work" in this platform
    // you could import someone's game that's just the rules to a clock or something
    // ok we need to stick things in the games's inventory
    const scope = this;
    let stringParts: string | string[];
    let numberParts: string[];
    let out: string;

    if (item.match(/\d/) === null) {
      //there's no quantity, we probably want to make 1
      stringParts = item;
      out = stringParts.trim();
      numberParts = ["1"];
    } else {
      stringParts = item.split(/\d/);
      numberParts = item.split(" ");
      out = stringParts[1].trim();
    }
    let quantity = parseInt(numberParts[0], 10);
    if (isNaN(quantity)) quantity = 1;
    for (let i = 0; i < quantity; i++) {
      scope.settingInventory = [...scope.settingInventory, out];
    }

    // to sum up,
    // if the user says "make 1 alarm clock"
    // we want to add "alarm clock" to the setting inventory
    // if the user says "make 2 alarm clocks"
    // we want to add "alarm clock" to the setting inventory twice

    // if the user says "make 1 alarm clock ++ make 1 bed"
    // we want to add "alarm clock" to the setting inventory
    // and then add "bed" to the setting inventory

    // if the user says "make 1 alarm clock ++ make 2 beds"
    // we want to add "alarm clock" to the setting inventory
    // and then add "bed" to the setting inventory twice
  };
  _unmake = (item: string) => {
    //game maker is trying to destroy "destroy 1 alarm clock";
    // we need to remove things from the games's inventory.
    let stringParts: string | string[];
    let numberParts: number[] | string[];
    let out;
    if (item.match(/\d/) === null) {
      //there's no quantity, we probably want to make 1
      stringParts = item;
      out = stringParts.trim();
      numberParts = [1];
    } else {
      stringParts = item.split(/\d/);
      numberParts = item.split(" ");
      out = stringParts[1].trim();
    }
    let quantity = Number(numberParts[0]);
    if (isNaN(quantity)) quantity = 1;
    const indexOfItem = this.latestSettingInventory.indexOf(out);
    if (indexOfItem === -1) return false;
    for (let i = indexOfItem; i < quantity + indexOfItem; i++)
      this.settingInventory.splice(i, quantity);
  };
  _drop = (item: string) => {
    //a method to remove things from player's inventory
    //we'll do same thing as _get but remove
    let stringParts: string | string[];
    let numberParts;
    let dropItem;
    if (!this.playerInventory?.length) {
      this._print(`You can't drop that.`);
      return false;
    }
    if (item.match(/\d/) === null) {
      //there's no quantity, we probably want to make 1
      stringParts = item;
      dropItem = stringParts.trim();
      numberParts = [1];
    } else {
      stringParts = item.split(/\d/);
      numberParts = item.split(" ");
      dropItem = stringParts[1].trim();
    }
    let quantity = Number(numberParts[0]);
    if (isNaN(quantity)) quantity = 1;
    //find the index of the item in the array and splice
    const itemsInInventory = this.playerInventory?.length;
    if (itemsInInventory) {
      if (quantity > itemsInInventory) {
        this._print(
          `You can't drop ${quantity} ${dropItem}, you only have ${itemsInInventory} ${dropItem}`
        );
        return false;
      }
    } else {
      this._print(
        `There ${quantity === 1 ? "is" : "are"} no ${dropItem} to drop.`
      );
      return false;
    }
    for (let i = 0; i < quantity; i++) this.spliceFromInventory(dropItem);
    for (let i = 0; i < quantity; i++)
      this.settingInventory = [...this.settingInventory, dropItem];
    this._print(`${quantity} ${dropItem} dropped`);

    //to sum up,
    //if the user says "drop 1 alarm clock"
    //we want to remove "alarm clock" from the player's inventory
    //if the user says "drop 2 alarm clocks"
    //we want to remove "alarm clock" from the player's inventory twice
  };
  spliceFromGameActions = (action: GameAction) => {
    // sometimes we want to remove an action from a player's things they can do.
    // maybe they've completed it, etc
    let actionIndex: number;
    this.latestGameActions.forEach((lAction, i) => {
      if (lAction.matchString === action.matchString) {
        actionIndex = i;
      }
    });
    this.gameActions.splice(actionIndex, 1);
  };
  _ask = (question: string) => {
    // ask the player a question and record the answer
    this.question = question;
    this.askingQuestion = true;
  };
  _answer = (answer: string) => {
    // what to print when a player answers the question
    this.questionAnswer = answer;
  };
  _get = (item: string) => {
    //assumes there is a named inventoriable item,
    //which exists in the current room, and can be collected
    //once it is collected it isn't collected again
    //it can be collected in multiples

    //if there are matching items in the setting
    //we remove them from that array
    //and we disallow player from getting more than that

    // we need to stick things in the player's inventory
    let stringParts: string | string[];
    let numberParts;
    let out: string;
    let itemIndex: number;
    if (!this.latestSettingInventory?.length) {
      this._print(`Nothing to pick up here.`);
      return false;
    }
    if (item.match(/\d/) === null) {
      //there's no quantity, we probably want to make 1
      stringParts = item;
      out = stringParts.trim();
      numberParts = [1];
    } else {
      stringParts = item.split(/\d/);
      numberParts = item.split(" ");
      out = stringParts[1].trim();
    }
    let quantity = Number(numberParts[0]);
    if (isNaN(quantity)) quantity = 1;
    const itemInSetting = this.latestSettingInventory.filter((settingItem) => {
      return settingItem === out;
    });
    const itemsInSetting = itemInSetting.length;
    if (itemsInSetting) {
      if (quantity > itemsInSetting) {
        this._print(
          `You can't pick up ${quantity} ${out}, there ${
            itemsInSetting === 1 ? "is" : "are"
          } only ${itemsInSetting} ${out}`
        );
        return false;
      }
      itemIndex = this.latestSettingInventory.indexOf(out);
      this.settingInventory.splice(itemIndex, quantity || 1);
    } else {
      this._print(
        `There ${quantity === 1 ? "is" : "are"} no ${out} to pick up.`
      );
      return false;
    }
    for (let i = 0; i < quantity; i++)
      this.playerInventory = [...this.playerInventory, out];
    this._print(`${quantity} ${out} added to inventory`);
  };
  _setting = (setting: string) => {
    //we need to change a player's setting
    if (setting === "print $setting") return false;
    this.playerSetting = setting;
  };
  _use = (item: string) => {
    //"use"-ing an inventory item performs an action and removes the item from inventory
    // drop item
    // some act
    console.log("//TODO: use " + item);
  };
  replaceVariablesInText = (text: string) => {
    //regex to match any words starting with $
    const vars = text.match(/\$[a-zA-Z0-9]+/g);
    let newLine = text;
    //check for them in latestVariables
    if (vars) {
      vars.forEach((varName: string) => {
        const varValue = this.latestVariables[varName];
        if (varValue) {
          newLine = newLine.replace(varName, varValue);
        }
      });
    }
    return newLine;
  };
  _print = (line: string) => {
    if (!line) return false;
    //the program wants to write to the screen.
    if (line !== "$setting") {
      this.printGameLine(this.replaceVariablesInText(line));
    } else {
      this.latestContextualGame !== "a"
        ? this.printGameLine(
            this.replaceVariablesInText(this.contextualGameInfo.playerSetting)
          )
        : this.printGameLine(this.replaceVariablesInText(this.currentSetting));
    }
  };
  _act = (does: string) => {
    //the program wants to add an action the player can do.
    this.setupPlayerAction("player types " + does);
  };
  _choose = (decision: string) => {
    //display a choice to the user.
    //user can select choice either by typing or tapping
    //supports n choices
    if (!decision) return false;
    decision = decision?.split("choose ")[1] || decision;
    console.log(decision);
    const choices = decision.split(" | ");
    const theChoice = {};
    this.printGameLine("Choose:");
    choices.forEach((choice, i) => {
      const numChoice = i + 1;
      this.printGameLine(`- ${choice} (${numChoice})`);
      theChoice[numChoice] = choice;
    });
    this.choiceContext = true;
    this.activeChoice = theChoice;
  };
  _attach = (url: string) => {
    let newUrl = this.replaceVariablesInText(url);
    this.printGameLine(<img alt={"image from " + newUrl} src={newUrl} />);
  };
  _set = (variable: string) => {
    //sets a variable to a value
    //or to set a variable that can be used in a later command
    //variables start with $

    console.log("var", variable);
    const split = variable.split(" ");
    const variableName = split[0]; //$thing
    const variableValue = variable.replace(split[0] + " ", ""); //dog
    let questionToAsk = "";

    if (variableValue.startsWith("ask")) {
      this.askingQuestion = true;
      this.questionVariable = variableName;
      questionToAsk = variableValue.replace("ask ", "");
      this._ask(questionToAsk);
    } else if (variableName.match(/\$/) !== null && variableValue) {
      this.variables[variableName] = variableValue;
    }
  };
  _unset = (variable: string) => {
    //unsets a variable
    if (!variable || !this.latestVariables[variable]) return false;
    const variableParts = variable.split(" ");
    const variableName = variableParts[0];
    delete this.variables[variableName];
  };
  _navigate = (url: string) => {
    // links a user to a website, a loom
    window.location.href = url;
  };
  printGameLine = (line: any) => {
    this.gameLines = [...this.gameLines, line];

    setTimeout(() => {
      // ($('.gameScreen') as any).scrollTo('100%');
    }, 200);
  };
  createGameActions = () => {
    //goes through the maker instructions array
    //and does something with each instruction (or doesn't)
    //why isn't there an argument?
    //TODO make argument instructions actually do something
    //clear current game actions
    this.gameActions = [];
    //and contextual games
    this.contextualGame = "";
    //skim through game commands and collect choices player can make
    this.latestMakerInstructions.forEach((instruction) => {
      console.log("instruction", instruction);
      if (instruction) this.setupPlayerAction(instruction);
    });
  };
  collectMakerInstructions = () => {
    //the program reads through the instructions provided by the game maker
    //and tries to figure out what to do with it
    if (!this.gameText) {
      this.printGameLine("No source text for game.");
      return false;
    }
    const allInstructions = this.latestGameText;
    this.makerInstructions = [];
    const splitLines = allInstructions.split(/\n/);
    splitLines.forEach((line) => {
      if (line.indexOf("game") > -1 || line.indexOf("player") > -1) {
        const theMakerAction = line.replace("/", "").trim();
        this.makerInstructions = [...this.makerInstructions, theMakerAction];
      }
    });
  };
  setupPlayerAction = (instruction: string) => {
    if (!instruction) return false;
    let flagsObject: {
      always?: string;
      when?: string;
      has?: string;
      near?: string;
      repeat?: string;
    } = {};

    let theAction: string; //allocate memory for runtime pointers
    let theReaction: string;
    let playerLvl;
    let playerLvlArray;
    let newInstruction = instruction.replace("/", "");
    // '--has key --near blue door --when $level 3 thing => {has: "key", near: "blue door", when: {$level: 3}}
    // flags can either be a flag or a flag and a value
    // so they can be "true" or a value
    // example, we can use the flag "--when" to check variables and the value can be "level 1"
    // or we can use the flag "--has" to check player inventory and the value can be "key" or "2 key"
    // or we can use the flag "--near" to check if the player is near a location in the "setting" variable - must appear in the setting variable to be true
    // or we can use the flag "--always" and and the flag can have no value but be true
    // depending on the flag we can parse the value differently
    // flags: [when, near, always, has]

    // check if this is a player action
    if (
      newInstruction.indexOf("player types ") > -1 &&
      newInstruction.indexOf("player types") < 1
    ) {
      let hasFlags = false;
      if (newInstruction.indexOf(" --") > -1) {
        hasFlags = true;
        const flagsString = newInstruction.slice(
          newInstruction.indexOf(" --"),
          newInstruction.length
        );
        //remove flags from instruction
        newInstruction = newInstruction.slice(0, newInstruction.indexOf(" --"));
        //get all the flags after the instruction. the first flag will be after the first ' --' in the string
        const flags = flagsString.split(" --");
        //remove first flag because it's not a flag
        flags.shift();

        flags.forEach((flag) => {
          if (flag.trim() === "") return false;
          const flagParts = flag.split(" ");
          const flagName = flagParts[0];
          const flagValue = flagParts[1];
          flagsObject[flagName] = flagValue;
        });
      }
      //figure out what logic i'm trying to do with player leveling
      //and put that in a separate model
      playerLvlArray = newInstruction.split(" lvl ");
      if (playerLvlArray) {
        playerLvl = playerLvlArray[1];
        playerLvl = Number(playerLvl);
        newInstruction = playerLvlArray[0];
        // trim stuff off the end
      } else {
        playerLvl = 0;
      }
      theAction = newInstruction.split(" = ")[0].replace("player types ", "");
      theReaction = newInstruction.replace(
        "player types " + theAction + " = ",
        ""
      );
      this.gameActions = [
        ...this.gameActions,
        {
          flags: flagsObject,
          matchString: theAction,
          then: theReaction,
          playerLvl: playerLvl,
          accessed: false,
          accessedTimes: 0,
          //if always, we don't destroy action on completion
          immortal: flagsObject.always && true,
        },
      ];
    }
    if (
      newInstruction.indexOf("game setup = ") > -1 &&
      newInstruction.indexOf("game setup = ") < 1
    )
      this._do(newInstruction.split("game setup = ")[1]);
    //these probably could be written as a loop with game verbs
    if (
      newInstruction.indexOf("game setting = ") > -1 &&
      newInstruction.indexOf("game setting = ") < 1
    )
      this.playerSetting = instruction.split("game setting = ")[1];
    if (
      newInstruction.indexOf("game set = ") > -1 &&
      newInstruction.indexOf("game set = ") < 1
    )
      this._set(newInstruction.split("game set = ")[1]);
    if (
      newInstruction.indexOf("game unset = ") > -1 &&
      newInstruction.indexOf("game unset = ") < 1
    )
      this._unset(newInstruction.split("game unset = ")[1]);
    if (
      newInstruction.indexOf("game make ") > -1 &&
      newInstruction.indexOf("game make ") < 1
    )
      this._make(newInstruction.split("game make ")[1]);
    if (
      newInstruction.indexOf("game print ") > -1 &&
      newInstruction.indexOf("game print ") < 1
    )
      this._print(newInstruction.split("game print ")[1]);
    if (
      newInstruction.indexOf("game ask ") > -1 &&
      newInstruction.indexOf("game ask ") < 1
    )
      this._ask(newInstruction.split("game ask ")[1]);
    if (
      newInstruction.indexOf("game choose ") > -1 &&
      newInstruction.indexOf("game choose ") < 1
    )
      this._choose(newInstruction.split("game choose ")[1]);
    if (
      newInstruction.indexOf("game prompt =") > -1 &&
      newInstruction.indexOf("game prompt =") < 1
    )
      this._prompt(newInstruction.split("game prompt =")[1]);
    if (
      newInstruction.indexOf("game parent = ") > -1 &&
      newInstruction.indexOf("game parent = ") < 1
    )
      this.contextualGame = newInstruction.split("game parent = ")[1];
    if (
      newInstruction.indexOf("game attach = ") > -1 &&
      newInstruction.indexOf("game attach = ") < 1
    )
      this._attach(newInstruction.split("game attach = ")[1]);
  };
  startedGameWith = (text: string) => {
    this.gameStartText = text;
  };
  resetPlayer = () => {
    this.clearPlayerInput();
    this.playerLevel = 0;
    this.playerSetting = "";
    this.playerActions = [];
    this.playerInventory = [];
  };
  spliceFromInventory = (item: string) => {
    //sometimes we want to remove an item from a player's inventory by selecting the item
    const itemIndex = this.playerInventory.indexOf(item);
    this.playerInventory.splice(itemIndex, 1);
  };
  clearPlayerInput = () => {
    //fixme - get rid of jquery dep
    const inputToBeCleared = String($(".playerInput").val()).replace("\n", "");
    if (!this.playerActions) return false;
    if (inputToBeCleared !== "")
      this.playerActions = [...this.playerActions, inputToBeCleared];
    $(".playerInput").val("");
  };
  tryPlayerInput = (input: string) => {
    let newInput = input;
    let actionHappened = false;
    let pressedEnter = false;
    let questionAnswered = false;
    let playerAnswer: { [key: string]: string } = {};

    if (newInput.indexOf("\n") > -1) pressedEnter = true;
    if (!pressedEnter) return false;
    newInput = newInput.replace("\n", "").trim();

    //check if the player is being asked a question
    if (this.currentAskingQuestion) {
      playerAnswer = {
        question: this.currentQuestion,
        answer: newInput,
      };

      if (this.currentQuestionVariable) {
        this.variables[this.currentQuestionVariable] = playerAnswer.answer;
      }

      this.playerAnswers.push(playerAnswer);
      this.askingQuestion = false;
      questionAnswered = true;
    }

    if (this.playerChoosing && !isNaN(Number(input))) {
      if (this.currentChoosing[newInput]) {
        //if we have a number option and it works
        //change input to that option
        newInput = this.currentChoosing[newInput];
        this.choiceContext = false;
        this.activeChoice = {};
      } else {
        //they typed a number and it's not an option
        this._print("That's not an option.");
        return false;
      }
    }
    console.log(toJS(this.latestGameActions));
    this.latestGameActions.forEach((action) => {
      console.log(toJS(action));
      const neededPlayerLevel = action.playerLvl;
      const currentPlayerLevel = this.currentPlayerLevel;

      console.log("neededLevel", neededPlayerLevel);
      console.log("currentLevel", currentPlayerLevel);

      let canAct = true;
      //some actions are gated by player level
      if (currentPlayerLevel < neededPlayerLevel) canAct = false;
      const regex = new RegExp(action.matchString.toLowerCase());
      const matchArray = newInput.toLowerCase().match(regex);

      //go through the flags and check if they allow the action to run
      //if any of the flags are false, we can't run the action
      console.log("actionFlags", action.flags);
      Object.keys(action.flags).forEach((flag) => {
        const flagValue = action.flags[flag];
        if (flag !== "always") {
          let flagSplit =
            flagValue.indexOf(" ") > -1 ? flagValue.split(" ") : false;
          if (flag === "when" && flagSplit) {
            if (this.latestVariables[flagSplit[0]] !== flagSplit[1])
              canAct = false;
          }
          if (flag === "near") {
            if (!this.latestPlayerSetting.includes(flagValue)) canAct = false;
          }
          if (flag === "has") {
            if (!this.latestPlayerInventory[flagSplit[0]]) canAct = false;
          }
          if (flag === "hasnt") {
            if (!this.latestPlayerInventory[flagSplit[0]]) canAct = false;
          }
          if (flag === "if") {
            if (this.latestVariables[flagSplit[0]] === false) canAct = false;
          }
        } else {
          action.immortal = true;
        }
      });

      if (matchArray?.length && canAct && !actionHappened) {
        if (matchArray[0] !== matchArray.input) return false;
        // something the player typed matches one of our game actions
        if (action.accessed) {
          //this is an action we've decided should be repeatable for some reason
          //and it's been used once already
          //things like "look room" might be used often and might
          //should we increment a count?
        } else {
          this.playerLevel++;
          //only reward a player for doing something the first time
        }
        this.printGameLine("> " + input); //make it happen
        this._do(action.then);
        if (action.immortal) {
          // if it wasn't a single use action
          action.accessed = true;
          action.accessedTimes = action.accessedTimes + 1;
        } else {
          // this was a single use action
          this.spliceFromGameActions(action);
        }
        actionHappened = true;
      }
    });
    if (!actionHappened && !questionAnswered) {
      this.clearPlayerInput();
      this.printGameLine("> " + input.replace("\n", ""));
      input.replace("\n", "").trim() === ""
        ? this._print("Odd, you don't do anything.")
        : this._print("Hmm, that isn't right.");
    } else if (questionAnswered) {
      this.clearPlayerInput();
      this.printGameLine("> " + input.replace("\n", ""));
      console.log(this.currentQuestionAnswer);
      this._print(this.currentQuestionAnswer || "Okay, got it.");
      this.questionAnswer = "";
    }
    this.updateContextualPlayerInfo();
  };
  setGameText = (text: string) => {
    this.gameText = text;
  };
  updateContextualPlayerInfo = () => {
    //if this game is contextual within another, we want to update the parent url
    const keyToUpdate = this.latestContextualGame && this.latestContextualGame;
    this.contextualPlayerInfo[keyToUpdate] = {
      playerAnswers: this.currentPlayerAnswers,
      playerLevel: this.currentPlayerLevel,
      playerInventory: this.latestPlayerInventory,
      gameLines: this.latestGameLines,
      variables: this.latestVariables,
      playerSetting: this.latestPlayerSetting,
      playerActions: this.latestPlayerActions,
    };

    console.log(toJS(this.contextualPlayerInfo));
  };
  start = () => {
    this.variables = [];
    this.gameLines = [];
    this.makerInstructions = [];
    this.settingInventory = [];
    this.resetPlayer();
    this.collectMakerInstructions();
    this.createGameActions();
    this.startedGameWith(this.latestGameText);
  };
  reactToGameTextChange = autorun(
    () => {
      if (!this) return false;
      if (
        this.latestGameText !== this.gameStartedText ||
        this.latestGameText !== ""
      )
        this.start();
    },
    { delay: 100 }
  );
}

export default Questa;
