import React, { useState } from "react";

import {
  Anchor,
  Burger,
  Container,
  createStyles,
  Group,
  Header,
  Drawer,
  ScrollArea
} from "@mantine/core";
import { useDisclosure,useViewportSize} from "@mantine/hooks";

const HEADER_HEIGHT = 84;

const useStyles = createStyles((theme) => ({
  inner: {
    height: HEADER_HEIGHT,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  burger: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  links: {
    paddingTop: theme.spacing.lg,
    height: HEADER_HEIGHT,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  mainLinks: {
    marginRight: -theme.spacing.sm,
  },

  mainLink: {
    textTransform: "uppercase",
    fontSize: 13,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[6],
    padding: `7px ${theme.spacing.sm}px`,
    fontWeight: 700,
    borderBottom: "2px solid transparent",
    transition: "border-color 100ms ease, color 100ms ease",

    "&:hover": {
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
      textDecoration: "none",
    },
  },

  secondaryLink: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[2]
        : theme.colors.gray[6],
    fontSize: theme.fontSizes.xs,
    textTransform: "uppercase",
    transition: "color 100ms ease",

    "&:hover": {
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
      textDecoration: "none",
    },
  },

  mainLinkActive: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    borderBottomColor:
      theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 5 : 6],
  },
}));

interface LinkProps {
  label: string;
  link: string;
}

interface DoubleHeaderProps {
  mainLinks: LinkProps[];
  userLinks: LinkProps[];
}

export function QHeader({ mainLinks, userLinks }: DoubleHeaderProps) {
  const [opened, { toggle }] = useDisclosure(false);
  const { classes, cx } = useStyles();
  const [active, setActive] = useState(0);
  const [open, setOpen] = useState(false);
  const { height } = useViewportSize();

  const mainItems = mainLinks
    ? mainLinks.map((item, index) => (
        <Anchor<"a">
          href={item.link}
          key={item.label}
          className={cx(classes.mainLink, {
            [classes.mainLinkActive]: index === active,
          })}
          onClick={(event) => {
            event.preventDefault();
            setActive(index);
          }}
        >
          {item.label}
        </Anchor>
      ))
    : [];

  const secondaryItems = userLinks
    ? userLinks.map((item) => {
        let outFunc: any;
        if (item.label === "Docs")
          outFunc = () => {
            setOpen(true);
          };
        return (
          <Anchor<"a">
            href={item.link}
            key={item.label}
            onClick={(event) => {
              event.preventDefault();
              outFunc();
            }}
            className={classes.secondaryLink}
          >
            {item.label}
          </Anchor>
        );
      })
    : [];

  return (
    <>
      <Header height={HEADER_HEIGHT}>
        <Container className={classes.inner}>
          <h1>Q.U.E.S.T.A.</h1>{" "}
          <h2>(Qualitative User Entry System for Text Adventures)</h2>
          <div className={classes.links}>
            <Group position="right">{secondaryItems}</Group>
            <Group spacing={0} position="right" className={classes.mainLinks}>
              {mainItems}
            </Group>
          </div>
          {/* <Burger
          opened={opened}
          onClick={toggle}
          className={classes.burger}
          size="sm"
        /> */}
        </Container>
      </Header>
      <Drawer
        opened={open}
        onClose={() => setOpen(false)}
        title="Docs"
        padding="xl"
        size="xl"
      >
        <h1>Docs</h1>
        <h2>Concepts</h2>
        <ScrollArea style={{ height: height-200 }}>
        <p>
          Q.U.E.S.T.A. is designed for easily creating text adventures by
          writing rules in readable english, instead of code. Adventures can be
          simple or more involved.
        </p>
        <p>There are two basic formats you can write rules with:</p>
        <li>game setup = [verb command] ++ [verb command]</li>
        <li>player types [some phrase] = [verb command] ++ [verb command]</li>
        <p>
          Using the <code>game setup</code> format, you'll supply the setup of
          your adventure. Create the text the player sees, setup any variables,
          change the default prompt, make inventory items.
        </p>
        <p>
          Using the <code>player types</code> format, you'll set up rules for
          how Q.U.E.S.T.A. should respond to different player-typed attempts.
        </p>
        <h2>Verbs</h2>
        <h3>Set</h3>
        <p>
          The <code>set</code> verb lets you set up variables for the game. You
          can use, modify or unset these variables as needed.
        </p>
        <p>
          Example:{" "}
          <code>
            game setup = set $dogs 2 ++ A man on the side of the road has $dogs
            in a box. You're driving by. ++ choose Do you stop? | Do you keep
            going?
          </code>
        </p>
        <h3>Print</h3>
        <p>
          Example:{" "}
          <code>
            game setup = print Whatever you type after print will be printed to
            the screen.
          </code>
        </p>
        <h3>Ask</h3>
        <p>
          Example:{" "}
          <code>
            game setup = print A wizard appears and asks your name. ++ ask What
            is your name?
          </code>
        </p>
        <h3>Make</h3>
        <p>
          <code>make</code> will create environment items. The player can see
          these when they access the <code>$setting</code> variable.
        </p>
        <h3>Choose</h3>
        <p>You can offer your player choices or options.</p>
        <p>
          Example: <code>choose Do you stop? | Do you keep going?</code>
        </p>
        <h3>Attach</h3>
        <p>
          You can attach images to screens using the <code>attach</code> verb.
        </p>
        <p>
          Example:{" "}
          <code>
            game setup = print Here is a picture of a dog. ++ attach
            https://fakedogimageurl.png
          </code>
        </p>
        <h3>Navigate</h3>
        <p>
          You can have your player navigate to other QUESTA adventures or
          arbitrary web urls using the <code>navigate</code> verb.
        </p>
        <p>
          Example: <code>player types google = navigate google.com</code>
        </p>
        <p>
          {" "}
          Example:{" "}
          <code>game setup = choose Do you stop? | Do you keep going?</code>
        </p>
        <h2>Tips</h2>
        <h3>Transmogrify an item</h3>
        <h3>Update a variable</h3>
        <h3></h3>

      {/* ... content */}
    </ScrollArea>
      </Drawer>
    </>
  );
}
